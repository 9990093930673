<template>
  <div class="w-full">
    <div class="w-full">
      <button
        class="w-fit flex items-center gap-x-2 rounded-[100px] px-6 py-2 mb-5 text-sm border border-greyscale-3"
        @click="$router.back()"
      >
        <arrow-left-icon />
        Back
      </button>
      <div class="w-full flex justify-between gap-x-3 flex-wrap gap-y-5">
        <div
          class="w-full md:max-w-[313px] bg-accent-green rounded-[10px] py-4 px-6 drop-shadow-[0px 12px 20px rgba(245, 245, 245, 0.25)]"
        >
          <div class="flex gap-x-1 items-center">
            <asset-type :asset="account.currency" />
          </div>

          <div class="text-[28px] font-medium text-primary my-2">
            {{ currencyOf(account.currency).symbol
            }}{{
              formatAmountToMajor(account.balance.available, account.currency)
            }}
          </div>
        </div>

        <div class="flex flex-wrap gap-x-3 gap-y-4">
          <app-button
            v-if="canSwap"
            variant="outlined"
            size="md"
            @click="toggleSwapModal"
          >
            <exchange-currency-icon />
            <span>Exchange</span>
          </app-button>
          <app-button
            v-if="hasPermission(LyncPermissions.transactions_write)"
            variant="primary"
            size="md"
            @click="handleSendTransaction"
          >
            <airplane-icon class="fill-white" />
            <span>Make a transfer</span>
          </app-button>
        </div>
      </div>

      <div
        class="w-full flex flex-wrap-reverse xl:flex-nowrap items-start justify-between gap-y-10 gap-x-10 mt-10"
      >
        <banking-account-transactions-graph
          :currency="account.currency"
          :account-id="account.id"
        />
        <fiat-account-details :account="account" />
      </div>

      <banking-account-transactions-table :account-id="account.id" />
    </div>
  </div>
  <app-modal
    :is-open="isSwapModalOpen"
    :handle-close="toggleSwapModal"
    size="xl"
    align-right
  >
    <swap-payment :close-modal="toggleSwapModal" :account="account" />
  </app-modal>
</template>

<script lang="ts" setup>
import { formatAmountToMajor } from "@/helpers";
import { currencyOf } from "@/helpers/currencies";
import { AccountsResponse } from "@/types";
import { LyncPermissions } from "@/security/permissions";
import { usePermission } from "@/composables/use-permission";
import { useDefaultAccount } from "@/composables/states";
import { useRouter } from "vue-router";
import { computed, ref } from "vue";

const props = defineProps<{
  account: AccountsResponse;
}>();

const router = useRouter();

const { hasPermission } = usePermission();
const { updateDefaultAccount } = useDefaultAccount();
const isSwapModalOpen = ref(false);

const toggleSwapModal = () => {
  isSwapModalOpen.value = !isSwapModalOpen.value;
};

const canSwap = computed(() => {
  return (
    props.account.features?.exchanges.supported_currencies.length &&
    hasPermission(LyncPermissions.transactions_write)
  );
});

const handleSendTransaction = () => {
  if (props.account) {
    updateDefaultAccount({
      id: props.account.id,
      currency: props.account.currency,
    });
    router.push("/transactions/send-fiat");
  }
};
</script>
