<template>
  <div
    class="w-full xl:w-[40%] max-w-[400px] bg-primary text-white pb-6 rounded-[10px] overflow-hidden"
  >
    <header class="w-full flex justify-between items-center px-6 py-5">
      <h4 class="w-full text-sm font-bold text-center text-white/8-">
        {{ asset.asset.currency }} Account Details
      </h4>
    </header>

    <div class="w-full flex flex-col gap-y-4">
      <div class="w-full px-5">
        <div
          class="flex py-3 px-3 rounded-[5px] text-[#B47E30] bg-[#FFF8ED] gap-x-2"
        >
          <span>
            <caution-icon class="w-6 h-6 fill-[#B47E30]" />
          </span>
          <span class="text-xs">
            Only deposit {{ asset.asset.currency }} from the
            <span class="capitalize font-bold"
              >{{ asset.asset.blockchain }} network</span
            >
            Deposits from other networks will be lost.
          </span>
        </div>
      </div>
      <div class="w-full max-w-[150px] mx-auto bg-white p-1">
        <div v-if="loading" class="w-full">
          <skeleton-loader :count="5" />
        </div>
        <img
          v-else-if="!loading && imgData"
          :src="imgData.src"
          alt="asset address"
        />
        <div
          v-else-if="error"
          class="text-text-primary my-4 text-sm text-center"
        >
          Error fetching deposit address qr code
        </div>
      </div>
      <div class="w-full px-5 flex justify-between items-center">
        <div class="max-w-[200px]">
          <div class="text-xs text-white/80 mb-1">Wallet Address</div>
          <div class="text-white text-sm font-medium">
            {{ asset.deposit_address?.address || "N/A" }}
          </div>
        </div>
        <button
          class="w-[25px] h-[25px] flex justify-center items-center rounded-full bg-greyscale-2/90"
          @click="copyText(asset.deposit_address?.address || '')"
        >
          <copy-icon />
        </button>
      </div>
      <div class="w-full px-5 flex justify-between items-center">
        <div class="max-w-[200px]">
          <div class="text-xs text-white/80 mb-1">Network</div>
          <div class="text-white text-sm font-medium capitalize">
            {{ asset.asset.blockchain }}
          </div>
        </div>
        <button
          class="w-[25px] h-[25px] flex justify-center items-center rounded-full bg-greyscale-2/90"
          @click="copyText(asset.asset.blockchain)"
        >
          <copy-icon />
        </button>
      </div>

      <div class="w-full px-5">
        <button
          class="w-full bg-white text-primary text-sm py-3 px-4 rounded-[4px] flex justify-center items-center gap-x-2"
          @click="copyText(asset.deposit_address?.address || '')"
        >
          <black-copy-icon /> Copy address
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { copyToClipboard } from "@/helpers";
import { AssetsResponse } from "@/types";
import { watch } from "vue";
import { onMounted } from "vue";
import { ref } from "vue";
import { useToast } from "vue-toast-notification";

const props = defineProps<{
  asset: AssetsResponse;
}>();

const toast = useToast();
const loading = ref(false);
const error = ref(false);

const imgData = ref<HTMLImageElement | null>(null);

const copyText = (text: string) => {
  copyToClipboard(text);
  toast.success("Copied to clipboard", {
    position: "top-right",
  });
};

const handleImageLoading = () => {
  loading.value = true;
  imgData.value = null;
  const img = new Image();
  img.src = props.asset.deposit_address?.qr_code_link;
  img.onload = () => {
    imgData.value = img;
    loading.value = false;
  };
  img.onerror = () => {
    loading.value = false;
    error.value = true;
  };
};

onMounted(() => {
  handleImageLoading();
});

watch(
  () => props.asset,
  () => {
    handleImageLoading();
  },
);
</script>
