<template>
  <main class="w-full flex h-screen bg-white">
    <side-bar :routes="routes" :handle-signout="toggleLogoutModal" />
    <section class="w-full h-full overflow-y-auto pb-10">
      <app-header
        :title="title"
        :handle-signout="toggleLogoutModal"
        :description="description"
      />
      <div class="w-full mt-0 mb-14 lg:mb-0">
        <slot />
      </div>
    </section>
    <app-modal
      size="md"
      :is-open="showLogoutModal"
      :handle-close="toggleLogoutModal"
    >
      <div class="w-full bg-white rounded-[10px] py-8 px-5">
        <p class="text-primary font-medium mb-10 text-center">
          Are you sure you want to signout?
        </p>

        <div class="flex justify-between items-center gap-x-3">
          <app-button size="lg" variant="outlined" @click="toggleLogoutModal"
            >Cancel</app-button
          >

          <app-button
            size="lg"
            variant="primary"
            :loading="submitting"
            @click="signout"
            >Signout</app-button
          >
        </div>
      </div>
    </app-modal>
  </main>
</template>

<script lang="ts" setup>
import {
  ActiveAccountIcon,
  ActiveBeneficiaryIcon,
  ActiveHomeIcon,
  ActiveSettingsIcon,
  ActiveTransactionIcon,
  InactiveAccountIcon,
  InactiveBeneficiaryIcon,
  InactiveHomeIcon,
  InactiveSettingsIcon,
  InactiveTransactionIcon,
} from "../../icons";
import { RouteProps } from "../props";
import { useWriteResource } from "@/composables/use-resource";
import { errorMessage } from "@/helpers/error";
import { useToast } from "vue-toast-notification";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useLoggedInBusiness } from "@/composables/states";
import { useIntervalFn } from "@vueuse/core";

interface LayoutProps {
  title: string;
  description: string;
}

defineProps<LayoutProps>();

const showLogoutModal = ref(false);
const router = useRouter();

const toggleLogoutModal = () => {
  showLogoutModal.value = !showLogoutModal.value;
};

const routes: RouteProps[] = [
  {
    name: "Dashboard",
    activeIcon: ActiveHomeIcon,
    inActiveIcon: InactiveHomeIcon,
    route: "/",
  },
  {
    name: "Beneficiaries",
    activeIcon: ActiveBeneficiaryIcon,
    inActiveIcon: InactiveBeneficiaryIcon,
    route: "/beneficiaries",
  },
  {
    name: "Transactions",
    activeIcon: ActiveTransactionIcon,
    inActiveIcon: InactiveTransactionIcon,
    route: "/transactions",
  },
  {
    name: "Accounts",
    activeIcon: ActiveAccountIcon,
    inActiveIcon: InactiveAccountIcon,
    route: "/accounts",
  },
  {
    name: "Settings",
    activeIcon: ActiveSettingsIcon,
    inActiveIcon: InactiveSettingsIcon,
    route: "/settings",
  },
];

const toast = useToast();
const { refreshBusiness } = useLoggedInBusiness();

useIntervalFn(
  async () => {
    await refreshBusiness();
  },
  1000 * 60 * 5,
  { immediate: true },
);

const { execute: handleSignout, submitting } = useWriteResource(
  "/onboarding/logout",
  "post",
  {
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);

const signout = async () => {
  await handleSignout();
  await refreshBusiness();
  router.push("/login");
};
</script>
