<template>
  <div class="w-full">
    <div v-if="!initialized" class="w-full flex flex-col gap-y-[6px]">
      <p v-if="task.state === 'COMPLETED'" class="mb-2 font-medium text-sm">
        Your {{ getNameTag(task.type) }} has been verified
      </p>
      <div class="w-full h-10 relative mb-2">
        <div
          v-if="task.state === 'COMPLETED'"
          class="text-sm flex items-center gap-x-1 absolute right-4 top-0 h-full text-text-primary"
        >
          <circle-check />
          Verified
        </div>
        <input
          :value="
            task.type === 'EMAIL_OTP'
              ? task.data.otp_code?.email || ''
              : task.type === 'PHONE_OTP'
                ? task.data.otp_code?.phone || ''
                : ''
          "
          type="text"
          :placeholder="`Enter ${getNameTag(task.type)}`"
          class="px-4 py-2 rounded-[5px] focus:outline-primary text-sm text-primary placeholder:text-sm bg-white border border-[#E2E2E2] w-full h-full"
          disabled
        />
      </div>
      <app-button
        v-if="task.state !== 'COMPLETED'"
        size="lg"
        variant="primary"
        :loading="initializing"
        @click="handleInitialization"
        >Send code</app-button
      >
      <app-button
        v-if="task.state === 'COMPLETED'"
        size="lg"
        variant="primary"
        @click="moveToNext()"
        >Next</app-button
      >
    </div>

    <div v-else class="w-full">
      <div class="w-full flex flex-col gap-y-[6px] my-4">
        <input
          v-model="verificationCode"
          type="text"
          :placeholder="`Enter verification code`"
          class="px-4 py-2 rounded-[5px] focus:outline-primary text-sm text-primary placeholder:text-sm bg-white border border-[#E2E2E2] h-10"
        />
      </div>

      <app-button
        :disabled="submitting"
        :loading="submitting"
        size="lg"
        variant="primary"
        @click="handleSubmission"
      >
        Verify
      </app-button>
      <p class="text-text-primary text-center text-sm mt-2">
        Didn't receive any code?
        <button
          class="font-bold text-sm underline underline-offset-2 disabled:cursor-not-allowed text-primary"
          :disabled="!!timer"
          @click="handleInitialization"
        >
          Resend code
          <span v-if="timer">{{ "in " + timer + "s" }}</span>
        </button>
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useWriteResource } from "@/composables/use-resource";
import { bankingUrl } from "@/helpers/apiClient";
import { errorMessage } from "@/helpers/error";
import { AccountRequestTask, QueryKeys } from "@/types";
import { useQueryClient } from "@tanstack/vue-query";
import { ref, watch } from "vue";
import { useToast } from "vue-toast-notification";

const props = defineProps<{
  task: AccountRequestTask;
  moveToNext: () => void;
}>();

const initialized = ref(false);
const toast = useToast();
const verificationCode = ref("");
const timer = ref(0);
const queryClient = useQueryClient();

const getNameTag = (tag: string) => {
  if (tag === "PHONE_OTP") {
    return "Phone number";
  } else if (tag === "EMAIL_OTP") {
    return "Email";
  } else {
    return "";
  }
};

const { execute: initializeTask, submitting: initializing } = useWriteResource(
  bankingUrl(`account-requests/tasks`),
  "post",
  {
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);

const { execute: submitTask, submitting: submitting } = useWriteResource(
  bankingUrl(`account-requests/tasks`),
  "post",
  {
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);

const handleInitialization = async () => {
  await initializeTask({
    resource: `/${props.task.id}/initialize`,
  });
  toast.success("Verification code sent", {
    position: "top-right",
  });
  initialized.value = true;
  timer.value = 60;
};

const handleSubmission = async () => {
  if (verificationCode.value) {
    await submitTask({
      resource: `/${props.task.id}/submit`,
      body: {
        data: {
          otp_code: verificationCode.value,
        },
      },
    });
    toast.success("Verification successful", {
      position: "top-right",
    });
    queryClient.invalidateQueries({ queryKey: [QueryKeys.ACCOUNT_REQUEST] });
    verificationCode.value = "";
    initialized.value = false;
  } else {
    toast.error("Enter the verification code", {
      position: "top-right",
    });
  }
};

watch(
  timer,
  (value) => {
    setTimeout(() => {
      if (value > 0) {
        timer.value = timer.value - 1;
      }
    }, 1000);
  },
  {
    immediate: true,
  },
);
</script>
