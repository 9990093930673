<template>
  <div class="w-full flex justify-between items-start">
    <div class="w-[45%]">
      <div class="text-text-primary text-sm mb-3">{{ title }}</div>
      <input
        type="text"
        class="bg-transparent text-[21px] text-text-primary border-0 active:border-0 focus:outline-none"
        value="0.00"
      />
    </div>
    <div class="w-[45%] flex flex-col items-end">
      <div class="text-text-primary text-sm mb-3 text-right">
        Bal. {{ formatAmount(assetValue.balance) }}
        {{ assetValue.asset.currency }}
      </div>
      <Menu as="div" class="relative inline-block text-left">
        <div>
          <MenuButton
            class="flex w-[110px] h-[35px] items-center rounded-[20px] bg-greyscale-2 px-3 py-2 gap-x-2 text-primary text-sm"
            :class="assetValue ? 'justify-between' : 'justify-end'"
          >
            <AssetType :asset="assetValue.asset.currency" />
            <arrow-down-icon class="min-w-[13px]" />
          </MenuButton>
        </div>
        <MenuItems
          class="absolute right-0 mt-2 w-fit origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none z-[20]"
        >
          <div class="p-1 flex flex-col gap-y-1">
            <MenuItem v-for="asset in assets" :key="asset.id">
              <button
                class="flex w-[100px] items-center gap-x-2 px-2 py-2"
                @click="() => handleAssetChange(asset)"
              >
                <AssetType :asset="asset.asset.currency" />
              </button>
            </MenuItem>
          </div>
        </MenuItems>
      </Menu>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Menu, MenuItems, MenuItem, MenuButton } from "@headlessui/vue";
import AssetType from "../shared/AssetType.vue";
import { ref } from "vue";
import { AssetsResponse } from "@/types";
import { formatAmount } from "@/helpers";

interface InputProps {
  title: string;
  assets: AssetsResponse[];
  defaultValue: AssetsResponse;
}

const props = defineProps<InputProps>();

const assetValue = ref<AssetsResponse>(props.defaultValue);

const handleAssetChange = (asset: AssetsResponse) => {
  assetValue.value = asset;
};
</script>
