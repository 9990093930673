<template>
  <app-table
    v-if="!isError"
    :data="transactions?.data.items || []"
    :columns="columns"
    :loading="isLoading"
    header-background
    @mobile-row-click="handleMobileRowClicked"
  >
    <template #column-account="props">
      <div v-if="props.row.direction === 'INBOUND'">
        <div class="flex items-center gap-x-1">
          <asset-type :asset="props.row.destination?.asset?.currency" />
          <span v-if="props.row.destination.asset?.blockchain"
            >({{ props.row.destination.asset?.blockchain }})</span
          >
        </div>
        <div
          v-if="props.row.destination.address"
          class="mt-1 text-text-secondary ml-6 text-xs"
        >
          {{ maskAddress(props.row.destination.address) }}
        </div>
      </div>
      <div v-if="props.row.direction === 'OUTBOUND'">
        <div class="flex items-center gap-x-1">
          <asset-type :asset="props.row.asset" />
          <span v-if="props.row.source.asset?.blockchain"
            >({{ props.row.source.asset?.blockchain }})</span
          >
        </div>
        <div
          v-if="props.row.source.asset?.address"
          class="mt-1 text-text-secondary ml-6 text-xs"
        >
          {{ maskAddress(props.row.source.asset?.address) }}
        </div>
      </div>
    </template>

    <template #column-status="props">
      <transaction-status :status="props.row.state" />
    </template>
    <template #column-amount="props">
      <div class="font-bold">
        {{
          `${formatAmount(props.row.source_amount.value)} ${
            props.row.source_amount.currency
          }`
        }}
      </div>
    </template>
    <template #column-type="props">
      <div class="flex gap-x-1">
        <debit-icon v-if="props.row.direction.toLowerCase() === 'outbound'" />
        <credit-icon
          v-else-if="props.row.direction.toLowerCase() === 'inbound'"
        />
        <span
          :class="
            props.row.direction.toLowerCase() === 'outbound'
              ? 'text-[#B96B6B]'
              : 'text-[#297FB0]'
          "
          >{{
            props.row.direction.toLowerCase() === "outbound"
              ? "Debit"
              : "Credit"
          }}</span
        >
      </div>
    </template>
    <template #column-beneficiary="props">
      <div
        v-if="
          props.row.destination.type === 'COUNTERPARTY' &&
          props.row.destination.counterparty
        "
      >
        <div class="mb-1 flex gap-x-2 items-start">
          <institution-icon
            v-if="props.row.destination.counterparty.type === 'FIAT'"
          />
          <wallet-icon
            v-if="props.row.destination.counterparty.type === 'CRYPTO'"
          />
          <div class="-mt-1">
            <div>
              {{ props.row.destination.counterparty.name.toLowerCase() }}
            </div>
            <div
              v-if="props.row.destination.counterparty.type === 'CRYPTO'"
              class="break-all text-xs text-text-secondary"
            >
              {{
                maskAddress(
                  props.row.destination.counterparty.crypto_details.address,
                )
              }}
            </div>
            <div
              v-if="props.row.destination.counterparty.type === 'FIAT'"
              class="break-all text-xs text-text-secondary"
            >
              {{
                props.row.destination.counterparty.fiat_details.details
                  .accountNumber
              }}
            </div>
          </div>
        </div>
      </div>
      <div
        v-else-if="
          props.row.destination.type === 'ASSET' && props.row.destination.asset
        "
      >
        <div
          v-if="
            props.row.source.type === 'COUNTERPARTY' &&
            props.row.source.counterparty
          "
        >
          <div
            v-if="props.row.source.counterparty.type === 'CRYPTO'"
            class="flex gap-x-2 items-center"
          >
            <wallet-icon />
            <div
              v-if="props.row.source.counterparty.crypto_details"
              class="break-all"
            >
              {{
                maskAddress(
                  props.row.source.counterparty.crypto_details.address,
                )
              }}
            </div>
          </div>
          <div
            v-if="props.row.source.counterparty.type === 'FIAT'"
            class="flex gap-x-2 items-start"
          >
            <institution-icon />
            <div>
              <div v-if="props.row.source.counterparty.name">
                {{ props.row.source.counterparty.name.toLowerCase() }}
              </div>
              <div
                v-if="props.row.source.counterparty.fiat_details"
                class="break-all text-xs text-text-secondary"
              >
                {{
                  maskAddress(
                    props.row.source.counterparty.crypto_details.address,
                  )
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #column-action="props">
      <button
        class="underline underline-offset-2"
        @click="openTransactionDetails(props.row.id)"
      >
        View
      </button>
    </template>

    <!-- mobile columns -->

    <template #column-mobile-account="props">
      <div class="flex justify-between items-start gap-x-3">
        <div class="">
          <debit-icon v-if="props.row.direction.toLowerCase() === 'outbound'" />
          <credit-icon
            v-else-if="props.row.direction.toLowerCase() === 'inbound'"
          />
        </div>

        <div>
          <div v-if="props.row.direction === 'INBOUND'">
            <div class="flex items-center gap-x-1">
              <asset-type :asset="props.row.destination?.asset?.currency" />
              <span v-if="props.row.destination.asset?.blockchain"
                >({{ props.row.destination.asset?.blockchain }})</span
              >
            </div>
            <div
              v-if="props.row.destination.address"
              class="mt-1 text-text-secondary ml-6 text-xs"
            >
              {{ maskAddress(props.row.destination.address) }}
            </div>
          </div>
          <div v-if="props.row.direction === 'OUTBOUND'">
            <div class="flex items-center gap-x-1">
              <asset-type :asset="props.row.asset" />
              <span v-if="props.row.source.asset?.blockchain"
                >({{ props.row.source.asset?.blockchain }})</span
              >
            </div>
            <div
              v-if="props.row.source.address"
              class="mt-1 text-text-secondary ml-6 text-xs"
            >
              {{ maskAddress(props.row.source.address) }}
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #column-mobile-amount="props">
      <div class="font-bold">
        {{
          `${formatAmount(props.row.source_amount.value)} ${
            props.row.source_amount.currency
          }`
        }}
      </div>
      <div class="mt-1 text-xs text-right">
        {{ formatDate(props.row.created_date) }}
      </div>
    </template>

    <template #empty-state>
      <div
        class="w-full max-w-[300px] mx-auto flex flex-col gap-y-3 justify-center items-center"
      >
        <empty-data />
        <h4 class="font-bold text-base lg:text-lg">No transactions</h4>
        <p class="text-base text-center text-text-primary">
          Make your first payment to a beneficiary, or swap your stableicon the
          fastest way
        </p>
      </div>
    </template>
  </app-table>
  <error-component v-else message="Error fetching transactions" />
  <transaction-details
    v-if="showTransactionDetails && activeTransactionId"
    :id="activeTransactionId"
    :type="ServiceType.CRYPTO"
    :ext-close-modal="closeTransactionDetails"
  />
</template>

<script setup lang="ts">
import { TableColumn } from "@/components/shared/table/table.props";
import { formatAmount, formatDate, maskAddress } from "@/helpers";
import { useGetTransactions } from "@/data-access/transactions";
import { ServiceType, CryptoTransactionResponse } from "@/types";
import { ref } from "vue";

const currentPage = ref(1);
const perPage = ref(5);

const showTransactionDetails = ref(false);
const activeTransactionId = ref<string | null>(null);

const openTransactionDetails = (transactionId: string) => {
  activeTransactionId.value = transactionId;
  showTransactionDetails.value = true;
};

const closeTransactionDetails = () => {
  showTransactionDetails.value = false;
  activeTransactionId.value = null;
};

const {
  isLoading,
  data: transactions,
  isError,
} = useGetTransactions(
  {
    page: currentPage,
    limit: perPage,
  },
  ServiceType.CRYPTO,
);

const columns: TableColumn<
  CryptoTransactionResponse & {
    action: string;
  }
>[] = [
  {
    label: "Date",
    selector: (row) => formatDate(row.created_date),
    dataIndex: "date",
    showOnMobile: false,
  },
  {
    label: "Amount",
    selector: () => {},
    dataIndex: "amount",
    showOnMobile: false,
  },
  {
    label: "Account",
    selector: () => {},
    dataIndex: "account",
    showOnMobile: false,
  },
  {
    label: "Type",
    selector: (row) =>
      `${row.direction.toLowerCase() === "outbound" ? "Debit" : "Credit"}`,
    dataIndex: "type",
    showOnMobile: false,
  },

  {
    label: "Status",
    selector: (row) => row.state,
    dataIndex: "status",
    showOnMobile: false,
  },
  {
    label: "",
    selector: () => {},
    dataIndex: "action",
    showOnMobile: false,
  },
  {
    label: "",
    selector: () => {},
    dataIndex: "mobile-account",
    showOnMobile: true,
  },
  {
    label: "",
    selector: () => {},
    dataIndex: "mobile-amount",
    showOnMobile: true,
  },
];

const handleMobileRowClicked = (row: CryptoTransactionResponse) => {
  openTransactionDetails(row.id);
};
</script>
