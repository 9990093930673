<template>
  <div class="relative w-full">
    <div class="w-full bg-white rounded-[10px]">
      <header
        class="w-full flex justify-between items-center border-b-2 border-greyscale-7 px-5 py-4"
      >
        <h3 class="text-sm text-primary font-[800]">Add a beneficiary</h3>

        <button class="inline w-[16px] h-[18px]" @click="closeModal">
          <close-icon />
        </button>
      </header>

      <div class="w-full px-5 pt-6">
        <RadioGroup v-model="beneficiaryType">
          <RadioGroupLabel class="sr-only">Benficiary type</RadioGroupLabel>
          <div class="w-full flex justify-between gap-x-4">
            <RadioGroupOption
              v-slot="{ checked }"
              as="template"
              :value="BeneficiaryType.BANK"
            >
              <div
                class="h-10 w-1/2 px-1 md:px-5 py-1 flex justify-center items-center text-primary gap-x-3 rounded-[5px] text-xs md:text-sm cursor-pointer"
                :class="
                  checked
                    ? 'bg-greyscale-1 font-[800]'
                    : 'font-medium bg-white border border-greyscale-1'
                "
              >
                Bank recipient
                <span class="-translate-y-[1px]">
                  <check-icon v-if="checked" />
                </span>
              </div>
            </RadioGroupOption>
            <RadioGroupOption
              v-slot="{ checked }"
              as="template"
              :value="BeneficiaryType.WALLET"
            >
              <div
                class="h-10 w-1/2 px-1 md:px-5 py-1 flex justify-center items-center text-primary gap-x-3 rounded-[5px] text-xs md:text-sm cursor-pointer"
                :class="
                  checked
                    ? 'bg-greyscale-1 font-[800]'
                    : 'font-medium bg-white border border-greyscale-1'
                "
              >
                Wallet address
                <span class="-translate-y-[1px]">
                  <check-icon v-if="checked" />
                </span>
              </div>
            </RadioGroupOption>
          </div>
        </RadioGroup>
      </div>

      <div class="w-full">
        <add-crypto-fiat-beneficiary
          v-if="beneficiaryType === BeneficiaryType.BANK"
          :close-modal="closeModal"
        />

        <add-beneficiary-wallet-form
          v-else
          :create-beneficiary="handleCreateWalletBeneficiary"
          :submitting="submitting"
          :currencies="currencies || []"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from "@headlessui/vue";
import { useReadResource, useWriteResource } from "@/composables/use-resource";
import { cryptoUrl } from "@/helpers/apiClient";
import { errorMessage } from "@/helpers/error";
import { useToast } from "vue-toast-notification";
import {
  AvailableAssetsResponse,
  QueryKeys,
  WalletBeneficiaryRequest,
} from "@/types";
import { onMounted } from "vue";
import { useQueryClient } from "@tanstack/vue-query";

const props = defineProps<{
  closeModal: () => void;
}>();

enum BeneficiaryType {
  WALLET = "WALLET",
  BANK = "BANK",
}

const toast = useToast();
const queryClient = useQueryClient();

const beneficiaryType = ref<BeneficiaryType>(BeneficiaryType.BANK);

const { fetch: fetchCurrencies, data: currencies } = useReadResource<
  Array<AvailableAssetsResponse>
>(`/crypto/v1/data/supported-assets`);

const { submitting, execute: createWalletBeneficiary } = useWriteResource(
  cryptoUrl("beneficiaries/corporate/crypto"),
  "post",
  {
    successTitle: "Beneficiary created",
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);

const handleCreateWalletBeneficiary = async (
  values: WalletBeneficiaryRequest,
) => {
  await createWalletBeneficiary({
    body: values,
  });
  queryClient.invalidateQueries({ queryKey: [QueryKeys.CRYPTO_BENEFICIARIES] });
  props.closeModal();
};

onMounted(() => {
  fetchCurrencies();
});
</script>
