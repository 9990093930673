<template>
  <div v-if="task.data.agreement" class="w-full">
    <p class="mb-5 font-medium text-base">
      {{ task.data.agreement.description || "" }}
    </p>

    <page-loader v-if="downloading" />

    <div v-else-if="pdfUrl" class="mb-5">
      <object
        v-if="pdfUrl"
        :data="pdfUrl"
        type="application/pdf"
        class="w-full h-96 border-red-100"
      >
        <iframe :src="pdfUrl" width="100%" height="100%" style="border: none">
          <p class="text-sm text-primary mb-2">
            This browser does not support PDFs. Please download the PDF to view
            it:
          </p>
          <a
            :href="pdfUrl"
            :download="`${props.task.data.agreement?.description}.pdf`"
            >Download PDF</a
          >
        </iframe>
      </object>

      <p
        v-if="task.state === 'COMPLETED'"
        class="flex gap-x-2 items-center my-5 text-sm"
      >
        <input checked type="checkbox" disabled class="w-4 h-4" />
        You've read and accepted this agreement
      </p>

      <label v-else class="flex gap-x-2 items-center text-sm mt-5">
        <input
          v-model="accepted"
          type="checkbox"
          class="w-4 h-4"
          name="accept-agreement"
        />
        <span>I've read and I accept the terms in the agreement</span>
      </label>
    </div>

    <app-button
      :disabled="submitting || !accepted"
      :loading="submitting"
      size="lg"
      variant="primary"
      @click="handleProceed"
    >
      Proceed
    </app-button>
  </div>
</template>

<script lang="ts" setup>
import { useWriteResource } from "@/composables/use-resource";
import apiClient, { bankingUrl } from "@/helpers/apiClient";
import { errorMessage } from "@/helpers/error";
import { AccountRequestTask, QueryKeys } from "@/types";
import { useQueryClient } from "@tanstack/vue-query";
import { ref, onMounted } from "vue";
import { useToast } from "vue-toast-notification";

const props = defineProps<{
  task: AccountRequestTask;
  moveToNext: () => void;
}>();

const downloading = ref(false);
const toast = useToast();
const accepted = ref(props.task.state === "COMPLETED" ? true : false);
const queryClient = useQueryClient();
const pdfUrl = ref<string | null>(null);

const { execute: submitTask, submitting: submitting } = useWriteResource(
  bankingUrl(`account-requests/tasks`),
  "post",
  {
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);

const handleDownloadAgreement = async () => {
  try {
    downloading.value = true;
    const res = await apiClient.get(
      bankingUrl(`/data/agreements/${props.task.data.agreement?.id}/download`),
      {
        responseType: "blob",
      },
    );
    downloading.value = false;

    pdfUrl.value = URL.createObjectURL(res.data);
  } catch (err) {
    downloading.value = false;
    toast.error(errorMessage(err), {
      position: "top-right",
    });
  }
};

const handleProceed = async () => {
  if (props.task.state === "COMPLETED") {
    props.moveToNext();
  } else if (accepted.value) {
    await submitTask({
      resource: `/${props.task.id}/submit`,
      body: {
        data: {
          agreement_id: props.task.data.agreement?.id,
        },
      },
    });
    queryClient.invalidateQueries({ queryKey: [QueryKeys.ACCOUNT_REQUEST] });
    props.moveToNext();
  } else {
    toast.error("Please accept agreement", {
      position: "top-right",
    });
  }
};

onMounted(() => {
  handleDownloadAgreement();
});
</script>
