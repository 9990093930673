<template>
  <auth-layout
    title="Reset your password"
    description="Enter your new password to continue"
  >
    <form class="w-full flex flex-col gap-y-4" @submit="onSubmit">
      <app-input
        type="password"
        name="password"
        v-bind="formFields.password"
        :error-message="errors.password"
        placeholder="Enter your new password"
        label="New password"
        required
      />
      <app-input
        type="password"
        name="confirmPassword"
        v-bind="formFields.confirmPassword"
        :error-message="errors.confirmPassword"
        placeholder="Enter your new password"
        label="Confirm new password"
        required
      />
      <app-button
        :loading="submitting"
        :disabled="submitting"
        type="submit"
        variant="primary"
        size="lg"
        >Reset password</app-button
      >
      <router-link
        class="text-tertiary text-sm text-center font-medium"
        to="/login"
        >Return to Log in</router-link
      >
    </form>
  </auth-layout>
</template>

<script lang="ts" setup>
import { useWriteResource } from "@/composables/use-resource";
import { errorMessage } from "@/helpers/error";
import { useForm } from "vee-validate";
import { reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toast-notification";
import * as yup from "yup";

interface ResetPasswordFields {
  password: string;
  confirmPassword: string;
}

const toast = useToast();
const route = useRoute();
const router = useRouter();

const { submitting, execute: handleResetPassword } = useWriteResource(
  "/onboarding/password-reset/complete",
  "post",
  {
    successTitle: "Password reset Successful",
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
      router.push("/login");
    },
  },
);

const { errors, handleSubmit, defineInputBinds } = useForm<ResetPasswordFields>(
  {
    validationSchema: yup.object({
      password: yup
        .string()
        .min(8)
        .required()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 number, and 1 symbol",
        )
        .label("Password"),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref("password"), undefined], "Passwords must match")
        .required()
        .label("Confirm password"),
    }),
  },
);

const onSubmit = handleSubmit(async (values) => {
  if (route.params.token) {
    await handleResetPassword({
      body: {
        new_password: values.password,
        token: route.params.token,
      },
    });

    router.push("/login?action=reset");
  }
});

const formFields = reactive({
  password: defineInputBinds("password"),
  confirmPassword: defineInputBinds("confirmPassword"),
});
</script>
