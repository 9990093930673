<template>
  <div class="relative w-full h-full bg-white overflow-y-auto">
    <button
      class="border w-10 h-10 rounded-full flex items-center justify-center absolute right-5 top-5"
      @click="closeModal"
    >
      <close-icon />
    </button>

    <div class="w-full px-5 pt-5">
      <h2 class="text-lg font-bold capitalize mb-5">Exchange</h2>
    </div>

    <swap-payment-confirmation
      v-if="showConfirmation && quote"
      :quote="quote"
      :loading="loading"
      :make-payment="handleMakePayment"
      :cancel-payment="handleCancelPayment"
    />

    <swap-transaction-success
      v-else-if="successfulTxn"
      :transaction="successfulTxn"
    />

    <div v-else-if="txnError" class="py-5">
      <transaction-error-modal
        :close-modal="closeModal"
        :error-message="txnError"
      />
    </div>

    <div v-else class="w-full px-5 py-5 flex flex-col gap-y-5">
      <div class="w-full">
        <div class="text-sm font-medium text-primary mb-3">Source Account</div>
        <div
          class="w-full px-2 md:px-4 py-3 text-primary rounded-[5px] text-xs md:text-sm cursor-pointer bg-greyscale-1 font-[800]"
        >
          <div>
            <div class="flex items-center gap-x-1 mb-2 text-xs">
              <asset-type :asset="account.currency" no-title />
              {{ account.currency }}
            </div>
            <div class="text-text-secondary font-medium text-[10px] md:text-xs">
              Bal:
              {{
                formatAmountToMajor(account.balance.available, account.currency)
              }}
            </div>
          </div>
        </div>
      </div>

      <app-input
        v-bind="formFields.destinationCurrency"
        name="destinationCurrency"
        label="Destination Account"
        type="select"
        :error-message="fieldErrors.destinationCurrency"
        placeholder="Select currency"
        required
      >
        <option value="" disabled>Select an account</option>
        <option v-for="it in swapCurrencies" :key="it" :value="it">
          {{ it }} Account
        </option>
      </app-input>

      <app-input
        v-bind="formFields.amount"
        name="amount"
        label="Amount"
        type="number"
        :error-message="fieldErrors.amount"
        inputmode="decimal"
        placeholder="0.00"
        required
      />

      <swap-payment-summary
        v-if="quote || exchangeRate"
        :exchange-rate="exchangeRate"
        :billing-amount="{
          amount: quote?.billing_amount.amount || 0,
          currency: quote?.billing_amount.currency || account.currency,
        }"
        :destination-amount="{
          amount: quote?.destination_amount.amount || 0,
          currency:
            quote?.destination_amount.currency || values.destinationCurrency,
        }"
      />

      <app-button
        :loading="submitting"
        :disabled="submitting || !quote"
        variant="primary"
        size="lg"
        type="submit"
        @click="handleShowConfirmation"
        >Proceed</app-button
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  AccountsResponse,
  SwapTransactionQuoteResponse,
  BankingTransactionResponse,
  QueryKeys,
} from "@/types";
import { formatAmountToMajor } from "@/helpers";
import { reactive, watch, computed, ref } from "vue";
import { useForm } from "vee-validate";
import { useWriteResource } from "@/composables/use-resource";
import { bankingUrl } from "@/helpers/apiClient";
import { useToast } from "vue-toast-notification";
import { errorMessage } from "@/helpers/error";
import { currencyOf } from "@/helpers/currencies";
import { useQueryClient } from "@tanstack/vue-query";
import { debounce } from "lodash";

const props = defineProps<{
  closeModal: () => void;
  account: AccountsResponse;
}>();

interface FormFields {
  amount: string;
  destinationCurrency: string;
}

const fieldErrors = reactive({
  amount: "",
  destinationCurrency: "",
});

const quote = ref<SwapTransactionQuoteResponse>();
const exchangeRate = ref("");
const showConfirmation = ref(false);
const successfulTxn = ref<BankingTransactionResponse>();
const txnError = ref<string>();
const queryClient = useQueryClient();

const { defineInputBinds, values } = useForm<FormFields>();
const toast = useToast();

const swapCurrencies = computed(() => {
  return props.account.features?.exchanges.supported_currencies || [];
});

const { submitting, execute: getQuote } = useWriteResource(
  bankingUrl("quotes/exchange"),
  "post",
  {
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);

const { submitting: loading, execute: makePayment } = useWriteResource(
  bankingUrl("payments/exchange"),
  "post",
  {
    successTitle: "Your payment is being processed",
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.ACCOUNTS],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.ACCOUNT, props.account.id],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.ACCOUNT_TRANSACTIONS, props.account.id],
      });
    },
    onError: (err) => {
      quote.value = undefined;
      txnError.value = errorMessage(err);
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);

const handleCancelPayment = () => {
  quote.value = undefined;
  showConfirmation.value = false;
};

const isRequiredFieldsEmpty = () => {
  if (!values.amount) {
    fieldErrors.amount = "Amount is required";
  }

  if (!values.destinationCurrency) {
    fieldErrors.amount = "Destination currency is required";
  }

  return !values.amount || !values.destinationCurrency;
};

const handleGetQuote = async () => {
  if (!isRequiredFieldsEmpty()) {
    const amount =
      Number(values.amount) *
      10 ** currencyOf(props.account.currency).precision;

    const res = await getQuote({
      body: {
        source_account_id: props.account.id,
        fixed_side: "source",
        amount,
        destination_currency: values.destinationCurrency,
      },
    });

    quote.value = res;
    exchangeRate.value = res.exchange_rate;
  }
};

const handleGetExhangeRate = async () => {
  if (values.destinationCurrency) {
    const res = await getQuote({
      body: {
        source_account_id: props.account.id,
        fixed_side: "source",
        amount: 100,
        destination_currency: values.destinationCurrency,
      },
    });
    exchangeRate.value = res.exchange_rate;
  }
};

const handleShowConfirmation = () => {
  if (quote.value) {
    showConfirmation.value = true;
  }
};

const handleMakePayment = async () => {
  if (quote.value) {
    const amount =
      Number(values.amount) *
      10 ** currencyOf(props.account.currency).precision;

    const txn = await makePayment({
      body: {
        source_account_id: props.account.id,
        fixed_side: "source",
        amount,
        destination_currency: values.destinationCurrency,
      },
    });

    quote.value = undefined;

    successfulTxn.value = txn;
  }
};

watch(
  () => values.amount,
  debounce(() => {
    if (Number(values.amount) > 0) {
      handleGetQuote();
      fieldErrors.amount = "";
    } else {
      quote.value = undefined;
    }
  }, 500),
);

watch(
  () => values.destinationCurrency,
  (val) => {
    exchangeRate.value = "";
    if (val) {
      handleGetExhangeRate();
    }
    fieldErrors.destinationCurrency = "";
  },
);

const formFields = reactive({
  amount: defineInputBinds("amount"),
  destinationCurrency: defineInputBinds("destinationCurrency"),
});
</script>
