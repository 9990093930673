<template>
  <auth-layout title="Create a Lync account">
    <form class="w-full flex flex-col gap-y-2" @submit="onSubmit">
      <div class="w-full flex flex-col md:flex-row gap-x-3 gap-y-2">
        <app-input
          type="text"
          name="first_name"
          v-bind="formFields.first_name"
          :error-message="errors.first_name"
          placeholder="Enter contact first name"
          label="Contact first name"
          required
        />
        <app-input
          type="text"
          name="last_name"
          v-bind="formFields.last_name"
          :error-message="errors.last_name"
          placeholder="Enter contact last name"
          label="Contact last name"
          required
        />
      </div>
      <app-input
        type="email"
        name="email"
        v-bind="formFields.email"
        :error-message="errors.email"
        placeholder="Enter your email address"
        label="Email address"
        required
      />
      <app-input
        type="password"
        name="password"
        v-bind="formFields.password"
        :error-message="errors.password"
        placeholder="Enter your password"
        label="Password"
        required
      />
      <phone-input
        label="Phone number"
        :error-message="phoneNumberError"
        :default-phone-code="defaultPhoneCode"
        @change="handlePhoneChange"
      />
      <app-input
        type="text"
        name="business_name"
        v-bind="formFields.business_name"
        :error-message="errors.business_name"
        placeholder="Enter your business name"
        label="Business name"
        required
      />
      <app-input
        v-bind="formFields.country"
        name="country"
        :error-message="errors.country"
        type="select"
        label="Country of Incorporation"
      >
        <option value="" selected disabled>Select country</option>
        <option
          v-for="country in countriesList"
          :key="country.iso3"
          :value="country.code"
        >
          {{ country.name }}
        </option>
      </app-input>
      <app-button
        :loading="submitting"
        :disabled="submitting"
        type="submit"
        variant="primary"
        size="lg"
        >Create account</app-button
      >
      <p class="text-sm text-text-primary text-center">
        By signing up, you agree to our
        <router-link class="text-tertiary font-medium" to="#"
          >Terms of Service</router-link
        >
        and
        <router-link class="text-tertiary font-medium" to="#"
          >Privacy Policy</router-link
        >
      </p>
      <p class="text-sm text-text-primary text-center mt-2">
        Already have an account?
        <router-link
          class="text-primary font-medium underline underline-offset-2"
          to="/login"
          >Log in</router-link
        >
      </p>
    </form>
  </auth-layout>
</template>

<script lang="ts" setup>
import { useForm } from "vee-validate";
import { reactive, ref, watch } from "vue";
import { countriesList, countryOf } from "@/helpers/countries";
import * as yup from "yup";
import { useWriteResource } from "@/composables/use-resource";
import { errorMessage } from "@/helpers/error";
import { useToast } from "vue-toast-notification";
import router from "@/router";

interface CreateAccountFields {
  business_name: string;
  email: string;
  password: string;
  country: string;
  first_name: string;
  last_name: string;
}

const toast = useToast();
const phoneNumber = ref("");
const phoneNumberError = ref("");
const defaultPhoneCode = ref("+234");

const { submitting, execute: handleCreateAccount } = useWriteResource(
  "/onboarding/register",
  "post",
  {
    successTitle: "Account creation Successful",
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);

const phoneNumberRegex = /^\+\d{1,3}\d{3,}$/;

const handlePhoneChange = (value: string) => {
  if (value.length < 8 || value.length > 15) {
    phoneNumberError.value = "Phone number must be between 8 and 15 characters";
    return;
  }
  if (!phoneNumberRegex.test(value)) {
    phoneNumberError.value = "Please enter a valid phone number";
    return;
  }
  phoneNumber.value = value;
};

const { errors, handleSubmit, defineInputBinds, values } =
  useForm<CreateAccountFields>({
    validationSchema: yup.object({
      business_name: yup.string().required().label("Business name"),
      email: yup.string().email().required().label("Email"),
      password: yup
        .string()
        .min(8)
        .required()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 number, and 1 symbol",
        )
        .label("Password"),
      country: yup.string().required().label("Country"),
      first_name: yup.string().required().label("Contact first name"),
      last_name: yup.string().required().label("Contact last name"),
    }),
  });

const onSubmit = handleSubmit(async (values) => {
  if (!phoneNumber.value) {
    phoneNumberError.value = "Phone number is required";
    return;
  }
  await handleCreateAccount({
    body: {
      ...values,
      phone: phoneNumber.value,
    },
  });

  router.push("/login?action=signup");
});

watch(
  () => values.country,
  (val) => {
    if (val) {
      defaultPhoneCode.value = countryOf(val).dial_code;
    }
  },
);

const formFields = reactive({
  email: defineInputBinds("email"),
  business_name: defineInputBinds("business_name"),
  password: defineInputBinds("password"),
  country: defineInputBinds("country"),
  first_name: defineInputBinds("first_name"),
  last_name: defineInputBinds("last_name"),
});
</script>
