<template>
  <ul
    v-if="transaction.source.type === 'ASSET'"
    class="w-full flex flex-col gap-y-3 capitalize mb-5"
  >
    <li
      class="w-full flex justify-between gap-x-10 items-center text-sm font-medium"
    >
      <div class="text-text-secondary">Asset</div>
      <div class="text-primary text-right capitalize">
        {{ transaction.source.asset?.currency }} ({{
          transaction.source.asset?.blockchain
        }})
        <router-link
          class="text-text-secondary underline"
          :to="`/accounts/crypto/${transaction.source?.asset?.id}`"
          >View</router-link
        >
      </div>
    </li>

    <li
      class="w-full flex justify-between gap-x-10 items-start text-sm font-medium"
    >
      <div class="text-text-secondary">Address</div>
      <div
        class="text-primary capitalize flex flex-col justify-start max-w-[500px] gap-x-3 gap-y-2 items-end text-right break-all"
      >
        {{ transaction.source.address }}
        <button
          v-if="transaction.source.address"
          class="text-xs bg-greyscale-2 px-3 py-2 rounded-[20px] flex items-center gap-x-2 cursor-pointer"
          @click="copyAddress(transaction.source.address)"
        >
          <copy-icon /> Copy Address
        </button>
      </div>
    </li>
  </ul>
</template>

<script lang="ts" setup>
import { copyToClipboard } from "@/helpers";
import { CryptoTransactionResponse } from "@/types";
import { useToast } from "vue-toast-notification";

defineProps<{
  transaction: CryptoTransactionResponse;
}>();

const toast = useToast();

const copyAddress = (address: string) => {
  copyToClipboard(address);
  toast.success("Copied to clipboard", {
    position: "top-right",
  });
};
</script>
