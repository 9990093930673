<template>
  <auth-layout
    title="Forgot your password?"
    description="Please enter your email address to 
get a link to reset your password."
  >
    <form class="w-full flex flex-col gap-y-4" @submit="onSubmit">
      <auth-banner v-if="showBanner" type="forget" />
      <app-input
        type="email"
        name="email"
        v-bind="formFields.email"
        :error-message="errors.email"
        placeholder="Enter your business email"
        label="Email address"
        required
      />

      <app-button
        :loading="submitting"
        :disabled="submitting"
        type="submit"
        variant="primary"
        size="lg"
        >Submit</app-button
      >
      <router-link
        class="text-tertiary text-sm text-center font-medium"
        to="/login"
        >Return to Log in</router-link
      >
    </form>
  </auth-layout>
</template>

<script lang="ts" setup>
import { useWriteResource } from "@/composables/use-resource";
import { errorMessage } from "@/helpers/error";
import { useForm } from "vee-validate";
import { ref } from "vue";
import { reactive } from "vue";
import { useToast } from "vue-toast-notification";
import * as yup from "yup";

interface ForgotPasswordFields {
  email: string;
}

const showBanner = ref(false);
const toast = useToast();

const { submitting, execute: handleForgotPassword } = useWriteResource(
  "/onboarding/password-reset/initiate",
  "post",
  {
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);

const { errors, handleSubmit, defineInputBinds } =
  useForm<ForgotPasswordFields>({
    validationSchema: yup.object({
      email: yup.string().email().required().label("Email"),
    }),
  });

const formFields = reactive({
  email: defineInputBinds("email"),
});

const onSubmit = handleSubmit(async (values) => {
  await handleForgotPassword({
    body: values,
  });
  showBanner.value = true;
  formFields.email.value = "";
});
</script>
