<template>
  <router-link
    v-if="account.crypto_account"
    :to="`/accounts/crypto/${account.crypto_account.id}`"
    class="block w-full lg:max-w-[400px] py-4 px-6 rounded-[10px] text-primary drop-shadow-[0px 12px 20px rgba(245, 245, 245, 0.25)]"
    :class="classObject"
  >
    <div
      class="flex justify-start items-center gap-x-2 text-base 2xl:text-lg leading-tight font-medium"
    >
      <span class="w-[22px] h-full">
        <component :is="accountIconAndColor.icon" />
      </span>
      <span>
        {{ account.crypto_account.asset.currency }}
        <span v-if="account.crypto_account.asset.blockchain" class="capitalize"
          >({{ account.crypto_account.asset.blockchain.toLowerCase() }})</span
        ></span
      >
    </div>
    <div class="text-2xl font-medium mt-3">
      ${{ formatAmount(account.crypto_account.balance_in_usd) }}
    </div>
    <div class="text-sm text-text-secondary mt-3">
      {{
        formatAmount(
          account.crypto_account.balance,
          account.crypto_account.precision,
        )
      }}
      {{ account.crypto_account.asset.currency }}
    </div>
  </router-link>
  <router-link
    v-else-if="account.banking_account"
    :to="`/accounts/fiat/${account.banking_account.id}`"
    class="block w-full lg:max-w-[400px] py-4 px-6 rounded-[10px] text-primary drop-shadow-[0px 12px 20px rgba(245, 245, 245, 0.25)]"
    :class="classObject"
  >
    <div
      class="flex justify-start items-center gap-x-2 text-base 2xl:text-lg leading-tight font-medium"
    >
      <span class="w-[22px] h-full">
        <component :is="accountIconAndColor.icon" />
      </span>
      <span>
        {{ account.banking_account.currency }}
      </span>
    </div>
    <div
      v-if="account.banking_account.balance"
      class="text-2xl font-medium mt-3"
    >
      {{ currencyOf(account.banking_account.currency).symbol }}
      {{
        formatAmountToMajor(
          account.banking_account.balance.available,
          account.banking_account.currency,
        )
      }}
    </div>
    <div
      v-if="account.banking_account.balance"
      class="text-sm text-text-secondary mt-3"
    >
      {{
        formatAmountToMajor(
          account.banking_account.balance.available,
          account.banking_account.currency,
        )
      }}
      {{ account.banking_account.currency }}
    </div>
  </router-link>
</template>

<script lang="ts" setup>
import { IAccount } from "@/types";
import {
  TetherIcon,
  UsdFlag,
  NairaFlag,
  GbpFlag,
  EurFlag,
  CurrencyIcon,
  UsdcIcon,
} from "../icons";
import { currencyOf } from "@/helpers/currencies";
import { computed } from "vue";
import { formatAmount, formatAmountToMajor } from "@/helpers";

interface CardProps {
  account: IAccount;
}

const props = defineProps<CardProps>();

const accountIconAndColor = computed(() => {
  const currency =
    props.account.banking_account?.currency ||
    props.account.crypto_account?.asset?.currency ||
    "";
  switch (currency) {
    case "USDC":
      return {
        icon: UsdcIcon,
        color: "accent-green",
      };
    case "USDT":
      return {
        icon: TetherIcon,
        color: "accent-blue",
      };
    case "USD":
      return {
        icon: UsdFlag,
        color: "usd-gradient",
      };
    case "NGN":
      return {
        icon: NairaFlag,
        color: "accent-green",
      };
    case "GBP":
      return {
        icon: GbpFlag,
        color: "default",
      };
    case "EUR":
      return {
        icon: EurFlag,
        color: "accent-blue",
      };
    default:
      return {
        icon: CurrencyIcon,
        color: "default",
      };
  }
});

const classObject = {
  "bg-accent-blue": accountIconAndColor.value.color === "accent-blue",
  "bg-accent-green": accountIconAndColor.value.color === "accent-green",
  "bg-usd-gradient": accountIconAndColor.value.color === "usd-gradient",
  "bg-[#FFF8ED]": !!accountIconAndColor.value.color,
};
</script>
