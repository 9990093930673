<template>
  <div class="w-full">
    <button
      class="w-fit flex items-center gap-x-2 rounded-[100px] px-6 py-2 mb-5 text-sm border border-greyscale-3"
      @click="$router.back()"
    >
      <arrow-left-icon />
      Back
    </button>
    <div class="w-full flex justify-between gap-x-3 flex-wrap gap-y-5">
      <div
        class="w-full md:max-w-[313px] bg-accent-green rounded-[10px] py-4 px-6 drop-shadow-[0px 12px 20px rgba(245, 245, 245, 0.25)]"
      >
        <div class="flex gap-x-1 items-center">
          <asset-type :asset="asset.asset.currency" />
          <span class="capitalize">({{ asset.asset.blockchain }})</span>
        </div>

        <div class="text-[28px] font-medium text-primary my-2">
          {{ formatAmount(asset.balance, asset.precision) }}
        </div>

        <div class="text-sm font-medium text-text-secondary mt-">
          ${{ formatAmount(asset.balance_in_usd) }}
        </div>
      </div>

      <div class="flex flex-wrap gap-x-3 gap-y-4">
        <app-button
          v-if="hasPermission(LyncPermissions.transactions_write)"
          variant="outlined"
          size="md"
          @click="handleSendAsset"
        >
          <span class="w-[18px] h-[18px] inline-block"><send-icon /></span>
          <span>Send</span>
        </app-button>

        <app-button
          variant="outlined"
          size="md"
          @click="toggleRecieveDepositModal"
        >
          <span class="w-[18px] h-[18px] inline-block"><receive-icon /></span>
          <span>Receive</span>
        </app-button>

        <app-button
          v-if="hasPermission(LyncPermissions.transactions_write)"
          variant="primary"
          size="md"
          @click="handleWithdrawUsd"
        >
          <span class="w-[18px] h-[18px] inline-block"
            ><dollar-icon class="fill-white"
          /></span>
          <span>Withdraw USD</span>
        </app-button>
      </div>
    </div>

    <div
      class="w-full flex flex-wrap-reverse xl:flex-nowrap items-start justify-between gap-y-10 gap-x-10 mt-10"
    >
      <crypto-account-transactions-graph
        :currency="asset.asset.currency"
        :asset-id="asset.id"
      />
      <wallet-details :asset="asset" />
    </div>

    <crypto-account-transactions-table :asset-id="asset.id" />
  </div>

  <app-modal
    size="lg"
    :is-open="isReceiveDepositModalOpen"
    :handle-close="toggleRecieveDepositModal"
  >
    <div class="w-full bg-white rounded-[10px]">
      <header
        class="w-full flex justify-between items-center border-b border-greyscale-7 px-5 py-4"
      >
        <h3 class="text-primary font-[800]">Wallet Deposit Details</h3>

        <button
          class="inline w-[16px] h-[18px]"
          @click="toggleRecieveDepositModal"
        >
          <close-icon />
        </button>
      </header>
      <div class="px-3 py-4">
        <asset-deposit-details :asset="asset" />
      </div>
    </div>
  </app-modal>
</template>

<script lang="ts" setup>
import { useDefaultAsset } from "@/composables/states";
import { LyncPermissions } from "@/security/permissions";
import { usePermission } from "@/composables/use-permission";

import { formatAmount } from "@/helpers";
import { AssetsResponse } from "@/types";
import { ref } from "vue";
import { useRouter } from "vue-router";

const props = defineProps<{
  asset: AssetsResponse;
}>();

const router = useRouter();
const { updateDefaultAsset } = useDefaultAsset();

const isReceiveDepositModalOpen = ref(false);
const { hasPermission } = usePermission();

const toggleRecieveDepositModal = () => {
  isReceiveDepositModalOpen.value = !isReceiveDepositModalOpen.value;
};

const handleSendAsset = () => {
  if (props.asset) {
    updateDefaultAsset({
      currency: props.asset.asset.currency,
      network: props.asset.asset.blockchain,
    });
    router.push("/transactions/send");
  }
};

const handleWithdrawUsd = () => {
  if (props.asset) {
    updateDefaultAsset({
      currency: props.asset.asset.currency,
      network: props.asset.asset.blockchain,
    });
    router.push("/transactions/withdraw");
  }
};
</script>
