<template>
  <div v-if="task.data.declaration" class="w-full">
    <p class="font-medium text-sm">{{ convertToWords(task?.type) }}</p>
    <p v-if="task.state === 'COMPLETED'" class="flex gap-x-2 items-center my-5">
      <input checked type="checkbox" disabled class="w-4 h-4" />
      You've accepted this declaration
    </p>
    <label v-else class="flex gap-x-2 items-start my-5 text-sm">
      <input
        v-model="accepted"
        type="checkbox"
        class="w-4 h-4"
        name="accept-declaration"
      />
      <span class="text-sm -mt-1">{{ task.data.declaration.text }}</span>
    </label>
    <app-button
      :disabled="submitting"
      :loading="submitting"
      size="lg"
      variant="primary"
      @click="handleProceed"
    >
      Proceed
    </app-button>
  </div>
</template>

<script lang="ts" setup>
import { useWriteResource } from "@/composables/use-resource";
import { bankingUrl } from "@/helpers/apiClient";
import { errorMessage } from "@/helpers/error";
import { convertToWords } from "@/helpers/index";
import { AccountRequestTask } from "@/types";
import { ref } from "vue";
import { useToast } from "vue-toast-notification";

const props = defineProps<{
  task: AccountRequestTask;
  moveToNext: () => void;
}>();

const toast = useToast();
const accepted = ref(false);

const { execute: submitTask, submitting: submitting } = useWriteResource(
  bankingUrl(`account-requests/tasks`),
  "post",
  {
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);

const handleProceed = async () => {
  if (props.task.state === "COMPLETED") {
    props.moveToNext();
  } else if (accepted.value) {
    await submitTask({
      resource: `/${props.task.id}/submit`,
      body: {
        data: {},
      },
    });
    props.moveToNext();
  } else {
    toast.error("Please accept declaration", {
      position: "top-right",
    });
  }
};
</script>
