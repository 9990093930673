<template>
  <aside
    class="fixed shadow-lg lg:shadow-none z-[50] bottom-0 left-0 lg:relative w-full lg:max-w-[225px] min-w-[225px] h-fit lg:h-full bg-greyscale-2 py-0 lg:py-10"
  >
    <header class="hidden lg:block px-9">
      <div role="banner" class="w-[91px]">
        <img src="@/assets/images/lync-logo.svg" alt="Lync By Waza" />
      </div>
    </header>
    <nav
      class="w-full flex flex-row gap-x-2 lg:flex-col justify-between lg:justify-start py-3 px-2 lg:px-9 lg:mt-10"
    >
      <nav-link
        v-for="(it, index) in routes"
        :key="`${it.route + index}`"
        :active-icon="it.activeIcon"
        :in-active-icon="it.inActiveIcon"
        :name="it.name"
        :route="it.route"
        :active="it.route == route.matched[0].path"
      />
    </nav>

    <!-- <hr class="hidden lg:block w-full h-[1px] mt-16" /> -->

    <!-- <div class="hidden lg:block w-full px-9 mt-4">
      <button
        class="w-full h-[42px] p-[12px] flex justify-start items-center gap-x-3"
        @click="handleSignout"
      >
        <div>
          <signout-icon />
        </div>
        <span class="text-sm font-[500] text-text-primary">Sign out</span>
      </button>
    </div> -->
  </aside>
</template>

<script lang="ts" setup>
import { useRoute } from "vue-router";
import { RouteProps } from "../props";

interface SidebarProps {
  routes: RouteProps[];
  handleSignout: () => void;
}

const route = useRoute();

defineProps<SidebarProps>();
</script>
