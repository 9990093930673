<template>
  <ul
    v-if="
      transaction.destination.type === 'COUNTERPARTY' &&
      transaction.destination.counterparty &&
      transaction.destination.counterparty.type === 'CRYPTO'
    "
    class="w-full flex flex-col gap-y-3 capitalize"
  >
    <li
      class="w-full flex justify-between gap-x-10 items-center text-sm font-medium"
    >
      <div class="text-text-secondary">Name</div>
      <div class="text-primary text-right">
        {{ transaction.destination.counterparty.name }}
      </div>
    </li>
    <li
      class="w-full flex justify-between gap-x-10 items-center text-sm font-medium"
    >
      <div class="text-text-secondary">Asset</div>
      <div class="text-primary text-right">
        {{ transaction.destination.counterparty.currency }}
        ({{ transaction.destination.counterparty.crypto_details?.network }})
      </div>
    </li>

    <li
      class="w-full flex justify-between gap-x-10 items-start text-sm font-medium"
    >
      <div class="text-text-secondary">Address</div>
      <div
        class="text-primary capitalize flex flex-col justify-start max-w-[500px] gap-x-3 gap-y-2 items-end text-right break-all"
      >
        {{ transaction.destination.counterparty.crypto_details?.address }}
        <button
          v-if="transaction.destination.counterparty.crypto_details"
          class="text-xs bg-greyscale-2 px-3 py-2 rounded-[20px] flex items-center gap-x-2 cursor-pointer"
          @click="
            copyAddress(
              transaction.destination.counterparty.crypto_details.address,
            )
          "
        >
          <copy-icon /> Copy Address
        </button>
      </div>
    </li>
  </ul>
  <ul
    v-else-if="
      transaction.destination.type === 'COUNTERPARTY' &&
      transaction.destination.counterparty &&
      transaction.destination.counterparty.type === 'FIAT'
    "
    class="w-full flex flex-col gap-y-3 capitalize"
  >
    <li
      class="w-full flex justify-between gap-x-10 items-center text-sm font-medium"
    >
      <div class="text-text-secondary">Name</div>
      <div class="text-primary text-right">
        {{ transaction.destination.counterparty.name.toLowerCase() }}
      </div>
    </li>

    <li
      class="w-full flex justify-between gap-x-10 items-center text-sm font-medium"
    >
      <div class="text-text-secondary">Type</div>
      <div class="text-primary text-right">
        {{ transaction.destination.counterparty.currency }}
        ({{
          transaction.destination.counterparty.fiat_details?.type === "FED_WIRE"
            ? "Wire"
            : transaction.destination.counterparty.fiat_details?.type
        }})
      </div>
    </li>

    <li
      class="w-full flex justify-between gap-x-10 items-start text-sm font-medium"
    >
      <div class="text-text-secondary">Account Number</div>
      <div
        class="text-primary capitalize flex justify-end max-w-[500px] gap-x-1 gap-y-2 items-center text-right break-all"
      >
        {{
          transaction.destination.counterparty.fiat_details?.details
            .accountNumber
        }}
        <button
          v-if="transaction.destination.counterparty.fiat_details"
          class="text-xs bg-greyscale-2 px-3 py-2 rounded-[20px] flex items-center gap-x-2 cursor-pointer"
          @click="
            copyAddress(
              transaction.destination.counterparty.fiat_details?.details
                .accountNumber,
            )
          "
        >
          <copy-icon />
        </button>
      </div>
    </li>

    <li
      class="w-full flex justify-between gap-x-10 items-start text-sm font-medium"
    >
      <div class="text-text-secondary">Routing Number</div>
      <div
        class="text-primary capitalize flex justify-end max-w-[500px] gap-x-1 gap-y-2 items-center text-right break-all"
      >
        {{
          transaction.destination.counterparty.fiat_details?.details
            .routingNumber
        }}
        <button
          v-if="transaction.destination.counterparty.fiat_details"
          class="text-xs bg-greyscale-2 px-3 py-2 rounded-[20px] flex items-center gap-x-2 cursor-pointer"
          @click="
            copyAddress(
              transaction.destination.counterparty.fiat_details?.details
                .routingNumber,
            )
          "
        >
          <copy-icon />
        </button>
      </div>
    </li>
  </ul>
</template>

<script lang="ts" setup>
import { copyToClipboard } from "@/helpers";
import { CryptoTransactionResponse } from "@/types";
import { useToast } from "vue-toast-notification";

defineProps<{
  transaction: CryptoTransactionResponse;
}>();

const toast = useToast();

const copyAddress = (address: string) => {
  copyToClipboard(address);
  toast.success("Copied to clipboard", {
    position: "top-right",
  });
};
</script>
